//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import alertRead from '~/graphql/mutations/alertRead.gql';

export default {
  props: {
    alert: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    colorScheme: {
      type: String,
      required: true,
    },
  },
  computed: {
    alertDate() {
      return this.$dateFns.format(this.alert.created_at, 'd LLL y H:m');
    },
    isDocument() {
      return [
        'new_document',
        'new_version',
      ].includes(this.alert?.type);
    },
    isUser() {
      return [
        'new_user',
        'new_admin',
      ].includes(this.alert?.type);
    },
    itemPath() {
      if (this.isDocument) {
        return this.localePath({name: 'document-uuid', params: {uuid: this.alert.document.uuid}});
      }
      if (this.isUser) {
        return this.localePath({name: 'account-gebruikersbeheer-uuid', params: {uuid: this.alert.user.uuid}});
      }
      return false;
    },
    unreadColor() {
      const hex = this.colorScheme;
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);

      return 'rgba(' + r + ', ' + g + ', ' + b + ', 0.07)';
    },
    alertTypeLabel() {
      switch (this.alert.type) {
        case 'new_version':
          return 'Nieuw document versie';
        case 'new_document':
          return 'Nieuw document';
        case 'new_user':
          return 'Nieuw gebruiker';
        case 'new_admin':
          return 'Nieuw schoolbeheerder';
      }
      return '';
    },
  },
  methods: {
    click(hide) {
      this.$apollo.mutate({
        mutation: alertRead,
        variables: {
          id: this.alert.id,
          hide,
        },
        refetchQueries: ['alerts'],
      });

      if (!hide) {
        this.$emit('opened');
        this.$router.push(this.itemPath);
      }
    },
  },
};
