import {TYPE_ALGEMEEN, TYPE_TAALEXAMEN, TYPE_EXAMEN, TYPE_STICHTING} from '~/constants/documents';

export const state = () => ({
  searchQuery: '',
  searchFilters: {
    kwalificaties: [],
    keuzedelen: [],
    crossOverExamens: [],
    cohorts: [],
    documentTypes: [TYPE_ALGEMEEN, TYPE_TAALEXAMEN, TYPE_EXAMEN, TYPE_STICHTING],
    taalexamens: [],
  },
  currentSort: 'cohort_title:asc',
  filterLabels: {
    kwalificaties: [],
    keuzedelen: [],
    crossOverExamens: [],
    cohorts: [],
  },
  currentPage: 1,
});

export const actions = {
  setSearchQuery({commit}, query) {
    commit('SET_SEARCH_QUERY', query);
  },

  setSearchFilter({commit}, payload) {
    commit('SET_SEARCH_FILTER', payload);
  },

  setFilterLabels({commit}, payload) {
    commit('SET_FILTER_LABELS', payload);
  },

  disableFilter({commit}, payload) {
    commit('DISABLE_FILTER', payload);
  },

  setCurrentPage({commit}, payload) {
    commit('SET_CURRENT_PAGE', payload);
  },

  setCurrentSort({commit}, payload) {
    commit('SET_CURRENT_SORT', payload);
  },
};

export const mutations = {
  SET_SEARCH_QUERY(state, payload) {
    state.searchQuery = payload;
    state.currentPage = 1;
  },
  SET_SEARCH_FILTER(state, payload) {
    state.searchFilters[payload.filterName] = payload.payload;
    state.currentPage = 1;
  },
  SET_FILTER_LABELS(state, payload) {
    state.filterLabels[payload.filterName] = payload.payload;
    state.currentPage = 1;
  },
  DISABLE_FILTER(state, payload) {
    const filterLabelIndex = state.filterLabels[payload.type].indexOf(payload);
    const searchFilterIndex = state.searchFilters[payload.type].indexOf(payload.id);

    if (filterLabelIndex > -1) {
      state.filterLabels[payload.type].splice(filterLabelIndex, 1);
    }

    if (searchFilterIndex > -1) {
      state.searchFilters[payload.type].splice(searchFilterIndex, 1);
    }

    state.currentPage = 1;
  },
  SET_CURRENT_PAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_CURRENT_SORT(state, payload) {
    state.currentSort = payload;
  },
};
