//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => {
    return {
      isLoading: true,
    };
  },
  computed: {
    colorScheme() {
      return this.$store.state.colorScheme;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.isLoading = false;
    });
  },
};
