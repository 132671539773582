export default ({store, redirect, route}) => {
  const ignoreList = [
    '/auth/2fa/instellen',
    '/auth/accept-terms',
  ];

  if (store.state.auth.loggedIn && !store.state.auth.user.two_factor_enabled) {
    if (ignoreList.includes(route.fullPath)) {
      return;
    }

    return redirect('/auth/2fa/instellen');
  }
};
