export const BlueLabel = () => import('../../components/base/BlueLabel.vue' /* webpackChunkName: "components/blue-label" */).then(c => wrapFunctional(c.default || c))
export const GreenLabel = () => import('../../components/base/GreenLabel.vue' /* webpackChunkName: "components/green-label" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/base/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/base/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const PurpleLabel = () => import('../../components/base/PurpleLabel.vue' /* webpackChunkName: "components/purple-label" */).then(c => wrapFunctional(c.default || c))
export const RedLabel = () => import('../../components/base/RedLabel.vue' /* webpackChunkName: "components/red-label" */).then(c => wrapFunctional(c.default || c))
export const IconApproved = () => import('../../components/icons/IconApproved.vue' /* webpackChunkName: "components/icon-approved" */).then(c => wrapFunctional(c.default || c))
export const IconArrowLeftCircle = () => import('../../components/icons/IconArrowLeftCircle.vue' /* webpackChunkName: "components/icon-arrow-left-circle" */).then(c => wrapFunctional(c.default || c))
export const IconArrowRightCircle = () => import('../../components/icons/IconArrowRightCircle.vue' /* webpackChunkName: "components/icon-arrow-right-circle" */).then(c => wrapFunctional(c.default || c))
export const IconBell = () => import('../../components/icons/IconBell.vue' /* webpackChunkName: "components/icon-bell" */).then(c => wrapFunctional(c.default || c))
export const IconChevronDown = () => import('../../components/icons/IconChevronDown.vue' /* webpackChunkName: "components/icon-chevron-down" */).then(c => wrapFunctional(c.default || c))
export const IconChevronUp = () => import('../../components/icons/IconChevronUp.vue' /* webpackChunkName: "components/icon-chevron-up" */).then(c => wrapFunctional(c.default || c))
export const IconClose = () => import('../../components/icons/IconClose.vue' /* webpackChunkName: "components/icon-close" */).then(c => wrapFunctional(c.default || c))
export const IconCloseCircle = () => import('../../components/icons/IconCloseCircle.vue' /* webpackChunkName: "components/icon-close-circle" */).then(c => wrapFunctional(c.default || c))
export const IconDownload = () => import('../../components/icons/IconDownload.vue' /* webpackChunkName: "components/icon-download" */).then(c => wrapFunctional(c.default || c))
export const IconError = () => import('../../components/icons/IconError.vue' /* webpackChunkName: "components/icon-error" */).then(c => wrapFunctional(c.default || c))
export const IconFilteredAsc = () => import('../../components/icons/IconFilteredAsc.vue' /* webpackChunkName: "components/icon-filtered-asc" */).then(c => wrapFunctional(c.default || c))
export const IconFilteredDesc = () => import('../../components/icons/IconFilteredDesc.vue' /* webpackChunkName: "components/icon-filtered-desc" */).then(c => wrapFunctional(c.default || c))
export const IconInfoCircle = () => import('../../components/icons/IconInfoCircle.vue' /* webpackChunkName: "components/icon-info-circle" */).then(c => wrapFunctional(c.default || c))
export const IconLogin = () => import('../../components/icons/IconLogin.vue' /* webpackChunkName: "components/icon-login" */).then(c => wrapFunctional(c.default || c))
export const IconLogout = () => import('../../components/icons/IconLogout.vue' /* webpackChunkName: "components/icon-logout" */).then(c => wrapFunctional(c.default || c))
export const IconSearch = () => import('../../components/icons/IconSearch.vue' /* webpackChunkName: "components/icon-search" */).then(c => wrapFunctional(c.default || c))
export const IconUnfiltered = () => import('../../components/icons/IconUnfiltered.vue' /* webpackChunkName: "components/icon-unfiltered" */).then(c => wrapFunctional(c.default || c))
export const IconUser = () => import('../../components/icons/IconUser.vue' /* webpackChunkName: "components/icon-user" */).then(c => wrapFunctional(c.default || c))
export const IconViewed = () => import('../../components/icons/IconViewed.vue' /* webpackChunkName: "components/icon-viewed" */).then(c => wrapFunctional(c.default || c))
export const User = () => import('../../components/user.vue' /* webpackChunkName: "components/user" */).then(c => wrapFunctional(c.default || c))
export const AccountActiveKwalificatieFilters = () => import('../../components/account/ActiveKwalificatieFilters.vue' /* webpackChunkName: "components/account-active-kwalificatie-filters" */).then(c => wrapFunctional(c.default || c))
export const AccountActiveKwalificatieFiltersCreateUser = () => import('../../components/account/ActiveKwalificatieFiltersCreateUser.vue' /* webpackChunkName: "components/account-active-kwalificatie-filters-create-user" */).then(c => wrapFunctional(c.default || c))
export const AccountGeneralTopbar = () => import('../../components/account/GeneralTopbar.vue' /* webpackChunkName: "components/account-general-topbar" */).then(c => wrapFunctional(c.default || c))
export const AccountKwalificatieFilter = () => import('../../components/account/KwalificatieFilter.vue' /* webpackChunkName: "components/account-kwalificatie-filter" */).then(c => wrapFunctional(c.default || c))
export const AccountNavigation = () => import('../../components/account/Navigation.vue' /* webpackChunkName: "components/account-navigation" */).then(c => wrapFunctional(c.default || c))
export const AccountStatusBar = () => import('../../components/account/StatusBar.vue' /* webpackChunkName: "components/account-status-bar" */).then(c => wrapFunctional(c.default || c))
export const AccountStichtingKwalificatieSectorToegang = () => import('../../components/account/StichtingKwalificatieSectorToegang.vue' /* webpackChunkName: "components/account-stichting-kwalificatie-sector-toegang" */).then(c => wrapFunctional(c.default || c))
export const Alerts = () => import('../../components/alerts/index.vue' /* webpackChunkName: "components/alerts" */).then(c => wrapFunctional(c.default || c))
export const AlertsItem = () => import('../../components/alerts/item.vue' /* webpackChunkName: "components/alerts-item" */).then(c => wrapFunctional(c.default || c))
export const AuthSteps = () => import('../../components/auth/steps.vue' /* webpackChunkName: "components/auth-steps" */).then(c => wrapFunctional(c.default || c))
export const DashboardCurrentActiveFilters = () => import('../../components/dashboard/CurrentActiveFilters.vue' /* webpackChunkName: "components/dashboard-current-active-filters" */).then(c => wrapFunctional(c.default || c))
export const DashboardDesktopSidebar = () => import('../../components/dashboard/DesktopSidebar.vue' /* webpackChunkName: "components/dashboard-desktop-sidebar" */).then(c => wrapFunctional(c.default || c))
export const DashboardDocumentLabel = () => import('../../components/dashboard/DocumentLabel.vue' /* webpackChunkName: "components/dashboard-document-label" */).then(c => wrapFunctional(c.default || c))
export const DashboardFilter = () => import('../../components/dashboard/Filter.vue' /* webpackChunkName: "components/dashboard-filter" */).then(c => wrapFunctional(c.default || c))
export const DashboardGeneralTopbar = () => import('../../components/dashboard/GeneralTopbar.vue' /* webpackChunkName: "components/dashboard-general-topbar" */).then(c => wrapFunctional(c.default || c))
export const DashboardMobileTopbar = () => import('../../components/dashboard/MobileTopbar.vue' /* webpackChunkName: "components/dashboard-mobile-topbar" */).then(c => wrapFunctional(c.default || c))
export const DashboardSortableLabel = () => import('../../components/dashboard/SortableLabel.vue' /* webpackChunkName: "components/dashboard-sortable-label" */).then(c => wrapFunctional(c.default || c))
export const DashboardStatusBar = () => import('../../components/dashboard/StatusBar.vue' /* webpackChunkName: "components/dashboard-status-bar" */).then(c => wrapFunctional(c.default || c))
export const DashboardStichtingSwitcher = () => import('../../components/dashboard/StichtingSwitcher.vue' /* webpackChunkName: "components/dashboard-stichting-switcher" */).then(c => wrapFunctional(c.default || c))
export const FormulateCalendar = () => import('../../components/formulate/Calendar.vue' /* webpackChunkName: "components/formulate-calendar" */).then(c => wrapFunctional(c.default || c))
export const FormulateDate = () => import('../../components/formulate/Date.vue' /* webpackChunkName: "components/formulate-date" */).then(c => wrapFunctional(c.default || c))
export const FormulateDropdown = () => import('../../components/formulate/Dropdown.vue' /* webpackChunkName: "components/formulate-dropdown" */).then(c => wrapFunctional(c.default || c))
export const FormulateToggle = () => import('../../components/formulate/Toggle.vue' /* webpackChunkName: "components/formulate-toggle" */).then(c => wrapFunctional(c.default || c))
export const ModalsGebruikerAfwijzen = () => import('../../components/modals/GebruikerAfwijzen.vue' /* webpackChunkName: "components/modals-gebruiker-afwijzen" */).then(c => wrapFunctional(c.default || c))
export const ModalsGeheimhoudingsVerklaring = () => import('../../components/modals/GeheimhoudingsVerklaring.vue' /* webpackChunkName: "components/modals-geheimhoudings-verklaring" */).then(c => wrapFunctional(c.default || c))
export const ModalsInfoTweeFactorAuth = () => import('../../components/modals/InfoTweeFactorAuth.vue' /* webpackChunkName: "components/modals-info-twee-factor-auth" */).then(c => wrapFunctional(c.default || c))
export const ModalsMobileOverlay = () => import('../../components/modals/MobileOverlay.vue' /* webpackChunkName: "components/modals-mobile-overlay" */).then(c => wrapFunctional(c.default || c))
export const ModalsNieuwWachtwoord = () => import('../../components/modals/NieuwWachtwoord.vue' /* webpackChunkName: "components/modals-nieuw-wachtwoord" */).then(c => wrapFunctional(c.default || c))
export const ModalsResetTweeFactorAuth = () => import('../../components/modals/ResetTweeFactorAuth.vue' /* webpackChunkName: "components/modals-reset-twee-factor-auth" */).then(c => wrapFunctional(c.default || c))
export const ModalsSearchHintModal = () => import('../../components/modals/SearchHintModal.vue' /* webpackChunkName: "components/modals-search-hint-modal" */).then(c => wrapFunctional(c.default || c))
export const TablesDocuments = () => import('../../components/tables/Documents.vue' /* webpackChunkName: "components/tables-documents" */).then(c => wrapFunctional(c.default || c))
export const TablesLogs = () => import('../../components/tables/Logs.vue' /* webpackChunkName: "components/tables-logs" */).then(c => wrapFunctional(c.default || c))
export const TablesPagination = () => import('../../components/tables/Pagination.vue' /* webpackChunkName: "components/tables-pagination" */).then(c => wrapFunctional(c.default || c))
export const TablesScholen = () => import('../../components/tables/Scholen.vue' /* webpackChunkName: "components/tables-scholen" */).then(c => wrapFunctional(c.default || c))
export const TablesUsers = () => import('../../components/tables/Users.vue' /* webpackChunkName: "components/tables-users" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
