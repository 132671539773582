//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    open: false,
    label: null,
  }),
  computed: {
    currentLabel: {
      get() {
        if (!this.label) {
          return this.context.options.find(({value}) => value.toString() === this.context.value.toString())?.label;
        }

        return this.label;
      },
      set(label) {
        this.label = label;
      },
    },
  },
  methods: {
    selectOption(option) {
      this.open = false;
      this.context.model = option.value;
      this.currentLabel = option.label;
    },
    hideOptions() {
      this.open = false;
    },
  },
};
