import {canManageAll} from '~/constants/roles';

export default ({store, redirect, route}) => {
  const ignoreList = [
    '/auth/2fa/instellen',
    '/auth/accept-terms',
  ];

  if (store.state.auth.loggedIn && !store.state.auth.user.voorwaarden_geaccepteerd) {
    if (canManageAll(store.state.auth.user.role_id)) {
      return;
    }

    if (ignoreList.includes(route.fullPath)) {
      return;
    }

    return redirect('/auth/accept-terms');
  }
};
