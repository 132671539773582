import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4bbb3d85 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _0d2fcfe7 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _866abdd8 = () => interopDefault(import('../pages/account/scholen/index.vue' /* webpackChunkName: "pages/account/scholen/index" */))
const _2e2f5143 = () => interopDefault(import('../pages/account/schoolbeheerders/index.vue' /* webpackChunkName: "pages/account/schoolbeheerders/index" */))
const _4f051f71 = () => interopDefault(import('../pages/account/schoolgebruikers/index.vue' /* webpackChunkName: "pages/account/schoolgebruikers/index" */))
const _b60fdcc4 = () => interopDefault(import('../pages/auth/accept-terms.vue' /* webpackChunkName: "pages/auth/accept-terms" */))
const _e6236ae6 = () => interopDefault(import('../pages/auth/inloggen.vue' /* webpackChunkName: "pages/auth/inloggen" */))
const _7cb04369 = () => interopDefault(import('../pages/auth/registreren/index.vue' /* webpackChunkName: "pages/auth/registreren/index" */))
const _054a91bc = () => interopDefault(import('../pages/auth/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/auth/wachtwoord-vergeten/index" */))
const _2e81e6d4 = () => interopDefault(import('../pages/account/gebruikersbeheer/create.vue' /* webpackChunkName: "pages/account/gebruikersbeheer/create" */))
const _682b5360 = () => interopDefault(import('../pages/account/scholen/aanmaken.vue' /* webpackChunkName: "pages/account/scholen/aanmaken" */))
const _7da30a0c = () => interopDefault(import('../pages/auth/2fa/instellen.vue' /* webpackChunkName: "pages/auth/2fa/instellen" */))
const _b12ddc4a = () => interopDefault(import('../pages/auth/registreren/beheerder.vue' /* webpackChunkName: "pages/auth/registreren/beheerder" */))
const _6e463405 = () => interopDefault(import('../pages/auth/wachtwoord-instellen/token.vue' /* webpackChunkName: "pages/auth/wachtwoord-instellen/token" */))
const _3ddb3763 = () => interopDefault(import('../pages/auth/wachtwoord-vergeten/token.vue' /* webpackChunkName: "pages/auth/wachtwoord-vergeten/token" */))
const _c6751c16 = () => interopDefault(import('../pages/account/gebruikersbeheer/_uuid/index.vue' /* webpackChunkName: "pages/account/gebruikersbeheer/_uuid/index" */))
const _72f5d87c = () => interopDefault(import('../pages/account/scholen/_id.vue' /* webpackChunkName: "pages/account/scholen/_id" */))
const _3a5992da = () => interopDefault(import('../pages/account/gebruikersbeheer/_uuid/logboek.vue' /* webpackChunkName: "pages/account/gebruikersbeheer/_uuid/logboek" */))
const _2409c9b8 = () => interopDefault(import('../pages/document/_uuid/index.vue' /* webpackChunkName: "pages/document/_uuid/index" */))
const _14c9ac49 = () => interopDefault(import('../pages/document/_uuid/logboek.vue' /* webpackChunkName: "pages/document/_uuid/logboek" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _4bbb3d85,
    pathToRegexpOptions: {"strict":true},
    name: "account___nl"
  }, {
    path: "/en",
    component: _0d2fcfe7,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/account/scholen",
    component: _866abdd8,
    pathToRegexpOptions: {"strict":true},
    name: "account-scholen___nl"
  }, {
    path: "/account/schoolbeheerders",
    component: _2e2f5143,
    pathToRegexpOptions: {"strict":true},
    name: "account-schoolbeheerders___nl"
  }, {
    path: "/account/schoolgebruikers",
    component: _4f051f71,
    pathToRegexpOptions: {"strict":true},
    name: "account-schoolgebruikers___nl"
  }, {
    path: "/auth/accept-terms",
    component: _b60fdcc4,
    pathToRegexpOptions: {"strict":true},
    name: "auth-accept-terms___nl"
  }, {
    path: "/auth/inloggen",
    component: _e6236ae6,
    pathToRegexpOptions: {"strict":true},
    name: "auth-inloggen___nl"
  }, {
    path: "/auth/registreren",
    component: _7cb04369,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registreren___nl"
  }, {
    path: "/auth/wachtwoord-vergeten",
    component: _054a91bc,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten___nl"
  }, {
    path: "/en/account",
    component: _4bbb3d85,
    pathToRegexpOptions: {"strict":true},
    name: "account___en"
  }, {
    path: "/account/gebruikersbeheer/create",
    component: _2e81e6d4,
    pathToRegexpOptions: {"strict":true},
    name: "account-gebruikersbeheer-create___nl"
  }, {
    path: "/account/scholen/aanmaken",
    component: _682b5360,
    pathToRegexpOptions: {"strict":true},
    name: "account-scholen-aanmaken___nl"
  }, {
    path: "/auth/2fa/instellen",
    component: _7da30a0c,
    pathToRegexpOptions: {"strict":true},
    name: "auth-2fa-instellen___nl"
  }, {
    path: "/auth/registreren/beheerder",
    component: _b12ddc4a,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registreren-beheerder___nl"
  }, {
    path: "/auth/wachtwoord-instellen/token",
    component: _6e463405,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-instellen-token___nl"
  }, {
    path: "/auth/wachtwoord-vergeten/token",
    component: _3ddb3763,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten-token___nl"
  }, {
    path: "/en/account/scholen",
    component: _866abdd8,
    pathToRegexpOptions: {"strict":true},
    name: "account-scholen___en"
  }, {
    path: "/en/account/schoolbeheerders",
    component: _2e2f5143,
    pathToRegexpOptions: {"strict":true},
    name: "account-schoolbeheerders___en"
  }, {
    path: "/en/account/schoolgebruikers",
    component: _4f051f71,
    pathToRegexpOptions: {"strict":true},
    name: "account-schoolgebruikers___en"
  }, {
    path: "/en/auth/accept-terms",
    component: _b60fdcc4,
    pathToRegexpOptions: {"strict":true},
    name: "auth-accept-terms___en"
  }, {
    path: "/en/auth/inloggen",
    component: _e6236ae6,
    pathToRegexpOptions: {"strict":true},
    name: "auth-inloggen___en"
  }, {
    path: "/en/auth/registreren",
    component: _7cb04369,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registreren___en"
  }, {
    path: "/en/auth/wachtwoord-vergeten",
    component: _054a91bc,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten___en"
  }, {
    path: "/en/account/gebruikersbeheer/create",
    component: _2e81e6d4,
    pathToRegexpOptions: {"strict":true},
    name: "account-gebruikersbeheer-create___en"
  }, {
    path: "/en/account/scholen/aanmaken",
    component: _682b5360,
    pathToRegexpOptions: {"strict":true},
    name: "account-scholen-aanmaken___en"
  }, {
    path: "/en/auth/2fa/instellen",
    component: _7da30a0c,
    pathToRegexpOptions: {"strict":true},
    name: "auth-2fa-instellen___en"
  }, {
    path: "/en/auth/registreren/beheerder",
    component: _b12ddc4a,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registreren-beheerder___en"
  }, {
    path: "/en/auth/wachtwoord-instellen/token",
    component: _6e463405,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-instellen-token___en"
  }, {
    path: "/en/auth/wachtwoord-vergeten/token",
    component: _3ddb3763,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten-token___en"
  }, {
    path: "/en/account/gebruikersbeheer/:uuid",
    component: _c6751c16,
    pathToRegexpOptions: {"strict":true},
    name: "account-gebruikersbeheer-uuid___en"
  }, {
    path: "/en/account/scholen/:id",
    component: _72f5d87c,
    pathToRegexpOptions: {"strict":true},
    name: "account-scholen-id___en"
  }, {
    path: "/en/account/gebruikersbeheer/:uuid?/logboek",
    component: _3a5992da,
    pathToRegexpOptions: {"strict":true},
    name: "account-gebruikersbeheer-uuid-logboek___en"
  }, {
    path: "/account/gebruikersbeheer/:uuid",
    component: _c6751c16,
    pathToRegexpOptions: {"strict":true},
    name: "account-gebruikersbeheer-uuid___nl"
  }, {
    path: "/account/scholen/:id",
    component: _72f5d87c,
    pathToRegexpOptions: {"strict":true},
    name: "account-scholen-id___nl"
  }, {
    path: "/en/document/:uuid",
    component: _2409c9b8,
    pathToRegexpOptions: {"strict":true},
    name: "document-uuid___en"
  }, {
    path: "/account/gebruikersbeheer/:uuid?/logboek",
    component: _3a5992da,
    pathToRegexpOptions: {"strict":true},
    name: "account-gebruikersbeheer-uuid-logboek___nl"
  }, {
    path: "/en/document/:uuid?/logboek",
    component: _14c9ac49,
    pathToRegexpOptions: {"strict":true},
    name: "document-uuid-logboek___en"
  }, {
    path: "/document/:uuid",
    component: _2409c9b8,
    pathToRegexpOptions: {"strict":true},
    name: "document-uuid___nl"
  }, {
    path: "/document/:uuid?/logboek",
    component: _14c9ac49,
    pathToRegexpOptions: {"strict":true},
    name: "document-uuid-logboek___nl"
  }, {
    path: "/",
    component: _0d2fcfe7,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
