import {startTracker} from '~/utils/tracker';

export default function ({store}) {
  if (process.env.OPENREPLAY_PROJECT_KEY) {
    startTracker({
      userIdEnabled: true,
      getUserId: () => {
        return store.state.auth?.user?.email || false;
      },
      projectKey: process.env.OPENREPLAY_PROJECT_KEY,
      isProduction: process.env.NODE_ENV === 'production',
    });
  }
};
