import { render, staticRenderFns } from "./GeneralTopbar.vue?vue&type=template&id=34b3feb8&"
import script from "./GeneralTopbar.vue?vue&type=script&lang=js&"
export * from "./GeneralTopbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconSearch: require('/data/www/examenbank.info/examenkluis/nuxt/components/icons/IconSearch.vue').default,IconInfoCircle: require('/data/www/examenbank.info/examenkluis/nuxt/components/icons/IconInfoCircle.vue').default,DashboardStichtingSwitcher: require('/data/www/examenbank.info/examenkluis/nuxt/components/dashboard/StichtingSwitcher.vue').default,Alerts: require('/data/www/examenbank.info/examenkluis/nuxt/components/alerts/index.vue').default,IconUser: require('/data/www/examenbank.info/examenkluis/nuxt/components/icons/IconUser.vue').default,IconLogout: require('/data/www/examenbank.info/examenkluis/nuxt/components/icons/IconLogout.vue').default,ModalsSearchHintModal: require('/data/www/examenbank.info/examenkluis/nuxt/components/modals/SearchHintModal.vue').default})
