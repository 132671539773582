//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import kwalificatiesQuery from '~/graphql/queries/documenten/kwalificaties';
import keuzedelenQuery from '~/graphql/queries/documenten/keuzedelen';
import crossOverExamensQuery from '~/graphql/queries/documenten/crossOverExamens';
import cohortsQuery from '~/graphql/queries/documenten/cohorts';
import {FILTER_EVENTS} from '~/constants/events';
import {TYPE_ALGEMEEN, TYPE_TAALEXAMEN, TYPE_EXAMEN, TYPE_STICHTING} from '~/constants/documents';

export default {
  apollo: {
    kwalificaties: {
      query: kwalificatiesQuery,
      variables() {
        return {
          query: this.kwalificatieSearchQuery,
          stichting: this.currentStichting.id,
        };
      },
      skip() {
        return this.kwalificatieSearchQuery === '';
      },
    },
    keuzedelen: {
      query: keuzedelenQuery,
      variables() {
        return {
          query: this.keuzedeelSearchQuery,
        };
      },
      skip() {
        return this.keuzedeelSearchQuery === '';
      },
    },
    crossOverExamens: {
      query: crossOverExamensQuery,
      variables() {
        return {
          query: this.crossOverExamenSearchQuery,
          stichting: this.currentStichting.id,
        };
      },
      skip() {
        return this.crossOverExamenSearchQuery === '';
      },
    },
    cohorts: {
      query: cohortsQuery,
      variables() {
        return {
          query: this.cohortSearchQuery,
          stichting: this.currentStichting.id,
        };
      },
      skip() {
        return this.cohortSearchQuery === '';
      },
    },
  },
  data: () => ({
    kwalificaties: [],
    keuzedelen: [],
    crossOverExamens: [],
    cohorts: [],
    filterEvents: FILTER_EVENTS,
    kwalificatieSearchQuery: '',
    keuzedeelSearchQuery: '',
    crossOverExamenSearchQuery: '',
    cohortSearchQuery: '',
    documentTypes: [
      {
        id: TYPE_ALGEMEEN,
        title: 'Algemeen examendocument',
      },
      {
        id: TYPE_TAALEXAMEN,
        title: 'Taalexamen',
      },
      {
        id: TYPE_EXAMEN,
        title: 'Examendocument',
      },
      {
        id: TYPE_STICHTING,
        title: 'Stichtingdocument',
      },
    ],
    taalexamenOptions: [
      {
        id: 1,
        title: 'Geïntegreerd',
      },
      {
        id: 2,
        title: 'Spreken',
      },
      {
        id: 3,
        title: 'Gesprekken voeren',
      },
      {
        id: 4,
        title: 'Schrijven',
      },
    ],
  }),
  computed: {
    colorScheme() {
      return this.$store.state.colorScheme;
    },
    currentStichting() {
      return this.$store.state.currentStichting;
    },
  },
  created() {
    this.$nuxt.$on(this.filterEvents.kwalificatieFilter.search, (query) => {
      this.kwalificatieSearchQuery = query;
    });
    this.$nuxt.$on(this.filterEvents.keuzedeelFilter.search, (query) => {
      this.keuzedeelSearchQuery = query;
    });
    this.$nuxt.$on(this.filterEvents.crossOverExamensFilter.search, (query) => {
      this.crossOverExamenSearchQuery = query;
    });
    this.$nuxt.$on(this.filterEvents.cohortFilter.search, (query) => {
      this.cohortSearchQuery = query;
    });
  },
};
