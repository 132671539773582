import { render, staticRenderFns } from "./default.vue?vue&type=template&id=2e7a78a3&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/data/www/examenbank.info/examenkluis/nuxt/components/base/Loader.vue').default,DashboardDesktopSidebar: require('/data/www/examenbank.info/examenkluis/nuxt/components/dashboard/DesktopSidebar.vue').default,DashboardMobileTopbar: require('/data/www/examenbank.info/examenkluis/nuxt/components/dashboard/MobileTopbar.vue').default,DashboardGeneralTopbar: require('/data/www/examenbank.info/examenkluis/nuxt/components/dashboard/GeneralTopbar.vue').default,ModalsMobileOverlay: require('/data/www/examenbank.info/examenkluis/nuxt/components/modals/MobileOverlay.vue').default})
