import stichtingenQuery from '~/graphql/queries/stichtingen/stichtingen.gql';
import {TYPE_ALGEMEEN, TYPE_TAALEXAMEN, TYPE_EXAMEN, TYPE_STICHTING} from '~/constants/documents';

export const state = () => ({
  release: process.env.RELEASE,
  stichtingen: [],
  colorScheme: {
    primary_color: '#5E47D2',
    primary_light_color: '#BCB0F5',
    secondary_color: '#F7F5FF',
    line_color: '#5E47D2',
  },
  currentStichting: null,
});

export const actions = {
  async nuxtServerInit({dispatch}) {
    try {
      await dispatch('storeDispatchFunc');
    } catch (e) {
      console.error(e);
    }
  },

  nuxtClientInit({state, commit}, context) {
    if (state.release) {
      context.store.dispatch('version/checkForNewVersion');
    }
  },

  async storeDispatchFunc({commit}) {
    const {data: {stichtingen}} = await this.app.apolloProvider.defaultClient.query({
      query: stichtingenQuery,
    });

    commit('SET_STICHTINGEN', stichtingen);
  },

  setColorScheme({commit}, stichting) {
    commit('SET_COLOR_SCHEME', {
      primary_color: stichting.primary_color || '#5E47D2',
      primary_light_color: stichting.primary_light_color || '#BCB0F5',
      secondary_color: stichting.secondary_color || '#F7F5FF',
      line_color: stichting.line_color || '#5E47D2',
    });
  },

  setCurrentStichting({commit}, stichting) {
    commit('SET_CURRENT_STICHTING', stichting);
  },
};

export const mutations = {
  SET_STICHTINGEN(state, payload) {
    state.stichtingen = payload;
  },
  SET_COLOR_SCHEME(state, payload) {
    state.colorScheme = payload;
  },
  SET_CURRENT_STICHTING(state, payload) {
    state.currentStichting = payload;
    state.dashboard.currentPage = 1;
    state.dashboard.currentSort = 'cohort_title:desc';
    state.dashboard.searchQuery = '';
    state.dashboard.searchFilters = {
      kwalificaties: [],
      keuzedelen: [],
      crossOverExamens: [],
      cohorts: [],
      documentTypes: [TYPE_EXAMEN, TYPE_ALGEMEEN, TYPE_TAALEXAMEN, TYPE_STICHTING],
      taalexamens: [],
    };
    state.dashboard.filterLabels = {
      kwalificaties: [],
      keuzedelen: [],
      crossOverExamens: [],
      cohorts: [],
    };
  },
};
