//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    stichtingenDropdown() {
      if (!this.$auth.loggedIn) {
        return [];
      }

      return this.$auth.user.stichtingen.map(({id, title}) => ({
        label: title,
        value: id,
      }));
    },
    defaultStichting: {
      get() {
        if (this.$cookies.get('default_stichting')) {
          const stichtingId = parseInt(this.$cookies.get('default_stichting'));

          if (this.updateColorScheme(stichtingId)) {
            return stichtingId;
          }
        }

        const stichtingId = this.$auth.loggedIn ? this.$auth.user.stichtingen[0].id : 0;
        this.updateColorScheme(stichtingId);

        return stichtingId;
      },
      set(stichtingId) {
        this.updateColorScheme(stichtingId);
      },
    },
  },
  methods: {
    switchStichting(stichtingId) {
      this.defaultStichting = parseInt(stichtingId);

      this.$router.push(this.localePath({name: 'index'}));
    },
    updateColorScheme(stichtingId) {
      if (!this.$auth.loggedIn || !this.$auth.user.stichtingen.map(stichting => stichting.id).includes(stichtingId)) {
        // User cannot select this stichting
        return false;
      }

      const stichting = this.$store.state.stichtingen.find(({id}) => id.toString() === stichtingId.toString());

      this.$store.dispatch('setCurrentStichting', stichting);
      this.$store.dispatch('setColorScheme', stichting);

      this.$cookies.set('default_stichting', stichtingId, {
        maxAge: 3600 * 24 * 60,
      });

      return true;
    },
  },
};
