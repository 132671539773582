//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    size: {
      type: Number,
      default: 6,
    },
    color: {
      type: String,
      default: '',
    },
  },
};
