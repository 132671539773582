import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';

import {v4 as uuidV4} from 'uuid';

function defaultGetUserId() {
  return uuidV4();
}

export function startTracker(config) {
  const getUserId = (config?.userIdEnabled && config?.getUserId && config?.getUserId()) ? config.getUserId : defaultGetUserId;
  let userId = null;

  const trackerConfig = {
    projectKey: config.projectKey,
    __DISABLE_SECURE_MODE: !config.isProduction,
  };

  const tracker = new Tracker(trackerConfig);

  if (config?.userIdEnabled) {
    userId = getUserId();
    tracker.setUserID(userId);
  }

  tracker.use(trackerAssist([]));
  tracker.start(trackerConfig);

  return {
    tracker,
    userId,
  };
}
