export const ROLE_SCHOOLGEBRUIKER = 2;
export const ROLE_REDACTEUR = 4;
export const ROLE_BEHEERDER = 5;
export const ROLE_DEVELOPER = 6;
export const ROLE_ONTWIKKELAAR = 7;
export const ROLE_ONTWIKKELAAR_PLUS = 9;
export const ROLE_HELPDESK_MEDEWERKER = 11;
export const ROLE_SCHOOLBEHEERDER = 12;

export function getRoleLabel(roleId) {
  switch (roleId) {
    case ROLE_SCHOOLGEBRUIKER:
      return 'Schoolgebruiker';
    case ROLE_SCHOOLBEHEERDER:
      return 'Schoolbeheerder';
    case ROLE_HELPDESK_MEDEWERKER:
      return 'Helpdeskmedewerker';
    case ROLE_ONTWIKKELAAR:
    case ROLE_ONTWIKKELAAR_PLUS:
      return 'Ontwikkelaar';
    case ROLE_REDACTEUR:
      return 'Redacteur';
    default:
      return 'Beheerder';
  }
}

export const ROLES = [
  ROLE_SCHOOLBEHEERDER,
  ROLE_SCHOOLGEBRUIKER,
];

export function canManageAll(roleId) {
  return [ROLE_DEVELOPER, ROLE_BEHEERDER, ROLE_HELPDESK_MEDEWERKER, ROLE_REDACTEUR, ROLE_ONTWIKKELAAR, ROLE_ONTWIKKELAAR_PLUS].includes(roleId);
}

export function canArchiveDocuments(roleId) {
  return [ROLE_DEVELOPER, ROLE_BEHEERDER, ROLE_REDACTEUR].includes(roleId);
}
