export const FILTER_EVENTS = {
  kwalificatieFilter: {
    search: 'kwalificatieSearch',
  },
  keuzedeelFilter: {
    search: 'keuzedeelSearch',
  },
  crossOverExamensFilter: {
    search: 'crossoverKwalificatieSearch',
  },
  cohortFilter: {
    search: 'cohortSearch',
  },
};
