//
//
//
//
//
//
//
//

export default {
  layout: 'auth',
  // eslint-disable-next-line vue/require-prop-types
  props: ['error'],
  data: () => ({
    content: {
      pageNotFound: {
        title: 'Pagina niet gevonden',
        description: 'Deze pagina bestaat niet meer of heeft nooit bestaan. Controleer de URL, of neem contact op met Examenbank.',
        button: 'Terug naar overzicht',
      },
      unAuthorized: {
        title: 'Sessie is verlopen',
        description: 'Dit komt meestal doordat je te lang inactief bent geweest.',
        button: 'Opnieuw inloggen',
      },
      default: {
        title: 'Er is iets misgegaan',
        description: 'Er is een onbekende fout opgetreden. Mocht het op een later moment nog niet werken neem dan contact op met Examenbank.',
        button: 'Terug naar overzicht',
      },
    },
  }),
  computed: {
    title() {
      return this.getContent('title');
    },
    description() {
      return this.getContent('description');
    },
    button() {
      return this.getContent('button');
    },
  },
  methods: {
    getContent(type) {
      switch (this.error.statusCode) {
        case 404:
          return this.content.pageNotFound[type];
        case 401:
          return this.content.unAuthorized[type];
        default:
          return this.content.default[type];
      }
    },
  },
};
