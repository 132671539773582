const middleware = {}

middleware['forceAcceptTerms'] = require('../middleware/forceAcceptTerms.js')
middleware['forceAcceptTerms'] = middleware['forceAcceptTerms'].default || middleware['forceAcceptTerms']

middleware['forceTwoFactor'] = require('../middleware/forceTwoFactor.js')
middleware['forceTwoFactor'] = middleware['forceTwoFactor'].default || middleware['forceTwoFactor']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['helpdeskmedewerker'] = require('../middleware/helpdeskmedewerker.js')
middleware['helpdeskmedewerker'] = middleware['helpdeskmedewerker'].default || middleware['helpdeskmedewerker']

middleware['schoolbeheerder'] = require('../middleware/schoolbeheerder.js')
middleware['schoolbeheerder'] = middleware['schoolbeheerder'].default || middleware['schoolbeheerder']

export default middleware
