export const actions = {
  checkForNewVersion({rootState}) {
    const interval = setInterval(async () => {
      let data;

      try {
        // Fetch last version
        const response = await this.$axios.get(location.origin + '/release-name.txt');

        data = response.data;
      } catch (e) {
        console.error(e);

        return;
      }

      const serverRelease = data.trim();

      if (serverRelease && rootState.release !== serverRelease) {
        this.$toast.info(
          'Er is een nieuwe versie beschikbaar. Vernieuw de pagina om deze te activeren.',
          {
            duration: 0,
            action: [
              {
                text: 'Vernieuwen',
                onClick: () => {
                  window.location.reload();
                },
              },
              {
                text: 'Sluiten',
                onClick: () => {
                  this.$toast.clear();
                },
              },
            ],
          },
        );

        clearInterval(interval);
      }
    }, 60000 * 10); // Every 10 min;
  },
};
