//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    input: '',
    showHintModal: false,
  }),
  computed: {
    searchQuery() {
      return this.$store.state.dashboard.searchQuery;
    },
    colorScheme() {
      return this.$store.state.colorScheme;
    },
  },
  watch: {
    '$store.state.dashboard.searchQuery'() {
      this.updateSearchQuery();
    },
  },
  methods: {
    updateSearchQuery() {
      this.input = this.$store.state.dashboard.searchQuery;
    },
    search() {
      this.$store.dispatch('dashboard/setSearchQuery', this.input);
      this.$router.push(this.localePath({name: 'index'}));
    },
  },
};
