import { render, staticRenderFns } from "./account.vue?vue&type=template&id=2b0f5480&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccountGeneralTopbar: require('/data/www/examenbank.info/examenkluis/nuxt/components/account/GeneralTopbar.vue').default,ModalsMobileOverlay: require('/data/www/examenbank.info/examenkluis/nuxt/components/modals/MobileOverlay.vue').default})
