export const state = () => ({
  gebruikers: {
    currentPage: 1,
    currentSort: 'created_at:desc',
    searchQuery: '',
  },
  beheerders: {
    currentPage: 1,
    currentSort: 'created_at:desc',
    searchQuery: '',
  },
});

export const actions = {
  setCurrentPageGebruikers({commit}, payload) {
    commit('SET_CURRENT_PAGE_GEBRUIKERS', payload);
  },

  setCurrentPageBeheerders({commit}, payload) {
    commit('SET_CURRENT_PAGE_BEHEERDERS', payload);
  },

  setCurrentSortGebruikers({commit}, payload) {
    commit('SET_CURRENT_SORT_GEBRUIKERS', payload);
  },

  setCurrentSortBeheerders({commit}, payload) {
    commit('SET_CURRENT_SORT_BEHEERDERS', payload);
  },

  setSearchQueryGebruikers({commit}, payload) {
    commit('SET_SEARCH_QUERY_GEBRUIKERS', payload);
  },

  setSearchQueryBeheerder({commit}, payload) {
    commit('SET_SEARCH_QUERY_BEHEERDERS', payload);
  },
};

export const mutations = {
  SET_CURRENT_PAGE_GEBRUIKERS(state, payload) {
    state.gebruikers.currentPage = payload;
  },
  SET_CURRENT_PAGE_BEHEERDERS(state, payload) {
    state.beheerders.currentPage = payload;
  },
  SET_CURRENT_SORT_GEBRUIKERS(state, payload) {
    state.gebruikers.currentSort = payload;
  },
  SET_CURRENT_SORT_BEHEERDERS(state, payload) {
    state.beheerders.currentSort = payload;
  },
  SET_SEARCH_QUERY_GEBRUIKERS(state, payload) {
    state.gebruikers.searchQuery = payload;
  },
  SET_SEARCH_QUERY_BEHEERDERS(state, payload) {
    state.beheerders.searchQuery = payload;
  },
};
