import Vue from 'vue';
import {nl} from '@braid/vue-formulate-i18n';
import errorHandler from '~/libs/form-error-handler';

import Date from '~/components/formulate/Date';
import Datepicker from '~/components/formulate/Calendar';
import Toggle from '~/components/formulate/Toggle';
import Dropdown from '~/components/formulate/Dropdown';

Vue.component('Date', Date);
Vue.component('Datepicker', Datepicker);
Vue.component('Toggle', Toggle);
Vue.component('Dropdown', Dropdown);

export default {
  validationNameStrategy: ['validationName', 'label', 'name', 'type'],
  library: {
    dropdown: {
      classification: 'select',
      component: Dropdown,
    },
    date: {
      classification: 'date',
      component: Date,
    },
    calendar: {
      classification: 'calendar',
      component: Datepicker,
      slotProps: {
        component: ['now'],
      },
    },
    toggle: {
      classification: 'checkbox',
      component: Toggle,
    },
  },
  plugins: [nl],
  classes: {
    input(context) {
      switch (context.classification) {
        case 'button':
          return 'mt-2 inline-flex px-4 py-[16px] rounded font-normal items-center justify-center gap-2 transition duration-200 bg-primary-light border text-primary border-primary-border hover-bg-primary-light hover-border-primary-border';
        case 'box':
          return '';
        case 'group':
          return '';
        case 'file':
          return 'appearance-none opacity-0 cursor-pointer absolute inset-0 w-full h-full z-10';
        default:
          return 'w-full rounded py-3.5 px-2 mt-2 mb-4 appearance-none text-gray-80 border border-gray-40 focus:outline-none focus:text-gray focus:border-primary-border';
      }
    },
    element({classification}) {
      switch (classification) {
        case 'box':
          return 'flex items-center px-1';
        default:
          return 'w-full relative';
      }
    },
    outer(context) {
      if (context.isGrouped) {
        return '';
      } else {
        switch (context.classification) {
          case 'button':
            return '';
          case 'radio':
            return '';
          default:
            return '';
        }
      }
    },
    wrapper(context) {
      switch (context.classification) {
        case 'button':
          return '';
        case 'box':
          return 'w-full flex justify-start';
        default:
          return '';
      }
    },
    label(context) {
      switch (context.classification) {
        case 'box':
          return 'font-body flex-grow ml-2 text-left truncate checkbox-label';
        default:
          return 'mt-2';
      }
    },
    /* decorator: ({hasValue}) => {
      let base = 'border rounded border-gray focus:border-primary-light inline-block w-4 h-4 mr-2';
      if (hasValue) {
        base += ' bg-blue-light';
      }
      return base;
    }, */
    help: 'text-xs mb-1 text-gray-dark',
    error: 'text-error text-xs mb-2.5 mt-[-10px]',
    uploadArea: 'upload-area',
    file: ' appearance-none rounded border p-3 block flex relative items-center justify-center',
    files: 'hidden',
    uploadAreaMask({attrs: {loading}}) {
      const base = 'absolute pointer-events-none flex justify-center items-center inset-0 border-2 border-dashed border-gray rounded upload-placeholder';

      if (loading) {
        return `${base} loading`;
      } else {
        return base;
      }
    },
  },
  errorHandler,
};
