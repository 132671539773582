//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    label: {
      type: String,
      default: () => 'Filter',
    },
    eventName: {
      type: String,
      default: () => 'filterUpdated',
    },
    currentFilterName: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    buttonColor: {
      type: String,
      default: () => '',
    },
    searchable: {
      type: Boolean,
      default: () => true,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => {
    return {
      showOptions: false,
      query: '',
      blockEvents: false,
      formData: {},
    };
  },
  computed: {
    colorScheme() {
      return this.$store.state.colorScheme;
    },
  },
  methods: {
    hideOptions() {
      if (this.blockEvents) {
        return;
      }

      this.showOptions = false;
    },
    onInput() {
      this.$nuxt.$emit(this.eventName, this.query);
      this.triggerOptions();
    },
    triggerOptions() {
      if (this.query === '') {
        this.showOptions = false;
        return;
      }

      this.formData = this.$store.state.dashboard.searchFilters[this.currentFilterName].reduce((obj, filter) => ({...obj, [filter]: true}), {});

      this.blockEvents = true;
      this.showOptions = true;

      setTimeout(() => {
        this.blockEvents = false;
      }, 100);
    },
    apply() {
      this.showOptions = false;

      this.$store.dispatch('dashboard/setSearchFilter', {
        filterName: this.currentFilterName,
        payload: Object.keys(this.formData).reduce((obj, filter) => {
          if (this.formData[filter]) {
            obj.push(parseInt(filter));
          }

          return obj;
        }, []),
      });

      if (this.searchable) {
        this.$store.dispatch('dashboard/setFilterLabels', {
          filterName: this.currentFilterName,
          payload: this.generateFilterLabels(),
        });
      }

      this.query = '';
    },
    generateFilterLabels() {
      return Object.keys(this.formData).reduce((obj, filter) => {
        if (this.formData[filter]) {
          const filterId = parseInt(filter);
          const option = this.options.find((option) => {
            return option.id === filterId;
          });

          if (option) {
            obj.push({
              id: filterId,
              label: option.title + (option.crebo ? ' (' + option.crebo + ')' : ''),
              type: this.currentFilterName,
              crebo: option.crebo ?? '',
              title: option.title,
            });
          } else {
            const option = this.$store.state.dashboard.filterLabels[this.currentFilterName].find((option) => {
              return option.id === filterId;
            });

            obj.push(option);
          }
        }

        return obj;
      }, []);
    },
  },
};
