export const state = () => ({
  currentPage: 1,
  currentSort: 'title:asc',
  searchQuery: '',
});

export const actions = {
  setCurrentPage({commit}, payload) {
    commit('SET_CURRENT_PAGE', payload);
  },

  setCurrentSort({commit}, payload) {
    commit('SET_CURRENT_SORT', payload);
  },

  setSearchQuery({commit}, payload) {
    commit('SET_SEARCH_QUERY', payload);
  },
};

export const mutations = {
  SET_CURRENT_PAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_CURRENT_SORT(state, payload) {
    state.currentSort = payload;
  },
  SET_SEARCH_QUERY(state, payload) {
    state.searchQuery = payload;
  },
};
